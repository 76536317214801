<template>
  <div>
    <el-dialog v-if="xqVisible" :close-on-click-modal="false" title="订单详情" width="80%" :visible.sync="xqVisible" :before-close="closeEvent">
    <div class="turnoverTab" style="overflow:scroll;">
    <div class="t-member" style="margin-bottom: 25px">
      <span class="t-span">客户: {{order.memberName==null?'散客':order.memberName}} {{order.cardName==null?'':'('+order.cardName+')'}}</span>
      <span class="t-span" v-if="order.phone">手机号: {{order.phone}}</span>
      <span class="t-span">客户归属: {{order.deptName==null?deptName:order.deptName}}</span>
      <span class="t-span">消费时间: {{order.createTime}}</span>
    </div>
    <div class="t-list">
      <div class="t-xf">
        <span>消费信息</span>
      </div>
      <div style="width:98%;margin: auto;margin-top: 18px;">
        <table
          cellspacing="0"
          border="0"
          width="100%"
          style="text-align: center"
        >
          <thead>
          <tr style="background-color: #F7F7F7;border:#EEEEEE solid 1px;">
            <th class="tab-th">名称</th>
            <th class="tab-th">单价</th>
            <th class="tab-th">数量</th>
<!--            <th class="tab-th">添加员工</th>-->
            <th class="tab-th">员工</th>
            <th class="tab-th">是否指定</th>
            <th class="tab-th">业绩</th>
            <th class="tab-th">提成</th>
            <th class="tab-th">分段提成</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in orderInfos">
            <td class="t-td-l" style="border-left:#EEEEEE solid 1px;">
              {{item.commodityName}}
            </td>
            <td class="t-td-l">{{item.price}}</td>
            <td class="t-td-l">1</td>
<!--            <td class="t-td-l"><span style="cursor: pointer;width: 15px;display: inline-block; font-size: 18px;"-->
<!--                                     @click="addEmp(item.id)">+</span></td>-->
            <td class="t-td-l">
              <table style="width:100%;">
                <tr v-if="item.empAcs.length>0" v-for="(ac,i) in item.empAcs">
                  <td class="empac" :class="{empac1:i==0}">{{ac.empName}}<span style="font-size: 8px;color: #ff4d51"> ({{ac.sxName}})</span>
<!--                    <span style="margin-left: 8px;cursor: pointer;color: #ff4d51;font-size: 8px;;float: right;padding-right: 12px" @click="toDel(ac)">删除</span>-->
                  </td>
                </tr>
                <tr v-if="item.empAcs.length==0">
                  <td class="empac1">暂无</td>
                </tr>
              </table>
            </td>
            <td class="t-td-l">
              <table style="width:100%;">
                <tr v-if="item.empAcs.length>0" v-for="(ac,i) in item.empAcs">
                  <td class="empac" :class="{empac1:i==0}">
                    <span v-if="ac.appoint==1">非指定</span>
                    <span v-if="ac.appoint==2">指定</span>
                  </td>
                </tr>
                <tr v-if="item.empAcs.length==0">
                  <td class="empac">暂无</td>
                </tr>
              </table>
            </td>
            <td class="t-td-l">
              <table style="width:100%">
                <tr v-if="item.empAcs.length>0" v-for="(ac,i) in item.empAcs">
                  <td class="empac" :class="{empac1:i==0}">{{ac.ach}}</td>
                </tr>
                <tr v-if="item.empAcs.length==0">
                  <td class="empac">暂无</td>
                </tr>
              </table>
            </td>
            <td class="t-td-l">
              <table style="width:100%">
                <tr v-if="item.empAcs.length>0" v-for="(ac,i) in item.empAcs">
                  <td class="empac" :class="{empac1:i==0}">{{ac.cmn}}</td>
                </tr>
                <tr v-if="item.empAcs.length==0">
                  <td class="empac">暂无</td>
                </tr>
              </table>
            </td>
            <td class="t-td-l">
              <table style="width:100%">
                <tr v-if="item.empAcs.length>0" v-for="(ac,i) in item.empAcs">
                  <td class="empac" :class="{empac1:i==0}">{{ac.fdCmn}}</td>
                </tr>
                <tr v-if="item.empAcs.length==0">
                  <td class="empac">暂无</td>
                </tr>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
      <div class="l-list" v-if="order.isFw">
        <div class="t-xf">
          <span>服务商品</span>
        </div>
        <div style="width:98%;margin: auto;margin-top: 18px;display: flex;flex-direction: column">
          <div v-for="item in orderInfos" style="margin-bottom: 5px" v-if="item.specNo||item.spec">
            <span class="nnnn" >编号:{{item.specNo}}</span> <span class="nnnn1">规格:{{item.spec}}</span>
          </div>
        </div>
      </div>
  </div>
    </el-dialog>
  </div>
</template>

<script>
  import {getSwName} from "../../utils/auth";

  export default {
    name: "xq",
    data() {
      return {
        deptName: getSwName(),
        orderId: '',
        orderInfoId: '',
        ach: 0,
        order: {},
        orderInfos: [],
        empVisible: false,

        acId:'',
        acDel: false,

        plabsList: [],
        plabsId: '',
        empList:[],
        empId:'',

        form: {},
        appoint: 1,
      };
    },
    async created() {
      this.orderId = this.id
      this.getOrderInfo()
    },
    props: {
      xqVisible:Boolean,
      id:String
    },
    methods: {
      closeEvent() {
        this.$emit("closeXQ")
      },
      toDel(item){
        this.acId=item.id
        this.acDel=true
      },

      async getOrderInfo() {
        let res = await this.$get("/sw/getSwOrderDetails", {orderId: this.orderId});
        this.order = res.order
        this.orderInfos = res.syOrderInfos
      },

    },
    components: {},
  };
</script>


<style lang="scss" scoped>

  .turnoverTab {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
  }

  .t-member {
    display: flex;
    flex-direction: column;
    height: 23%;
    justify-content: center;
    justify-items: center;
  }

  .t-span {
    width: 100%;
    height: 28px;
    line-height: 28px;
    margin-left: 55px;
  }

  .t-list {
    display: flex;
    flex-direction: column;
    overflow:scroll;
  }
  .l-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .t-xf {
    height: 28px;
    line-height: 28px;
    background-color: #F7F7F7;
    padding-left: 20px;
  }

  .t-xf span {
    border-left: #2b94ff solid 2px;
    padding-left: 5px;
    font-weight: bold;
  }

  .tab-th {
    width: 12%;
    height: 38px;
    line-height: 38px;
    font-weight: bold;
  }

  .table-responsive {
    border: 2px solid rgb(40, 40, 40, .5);
    color: rgb(40, 40, 40);
  }

  .t-td-l {
    padding: 0;
    height: 30px;
    line-height: 30px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-right: #EEEEEE solid 1px;
    border-bottom: #EEEEEE solid 1px;
    font-size: 13px;
  }

  .empac {
    border-top: #EEEEEE solid 1px;
  }

  .empac1 {
    border-top: #EEEEEE solid 0px;
  }
  .nnnn{
    display: inline-block;
    width: 150px;
  }
  .nnnn1{
     display: inline-block;
     width: 300px;
   }
</style>
