<template>
  <div style="width:100%; height: 100%;overflow:scroll;">
    <div class="ls">
      <div class="eti">营业流水
      </div>
    </div>
    <div class="ls-box">
      <span class="v-form-label">姓名 / 电话:</span>
      <div>
        <el-input placeholder="姓名 / 电话" size="mini" v-model="condition" clearable/>
      </div>
      <span class="v-form-label">订单号:</span>
      <div>
        <el-input placeholder="订单号" class="input-with-select" size="mini" v-model="orderNo" clearable/>
      </div>
      <span class="v-form-label">订单类型:</span>
      <div>
        <el-select v-model="orderType" clearable placeholder="订单类型" size="mini">
          <el-option
            v-for="item in orderTypes"
            :key="item.value"
            :label="item.name"
            :value="item.value"/>
        </el-select>
      </div>
      <span class="v-form-label">日期:</span>
      <div>
        <el-date-picker
          size="mini"
          v-model="dates"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"/>
      </div>
      <el-button
        style="margin-left: 18px" slot="append" size="small" @click="chaxun()">查询
      </el-button>
    </div>
    <div class="ls-nr">
      <div class="ls-titel">
        <span style="width: 12%;">客户</span>
        <span style="width: 12%;">性别</span>
        <span style="width: 17%">内容</span>
        <span style="width: 11%">原价</span>
        <span style="width: 12%">结算金额即方式</span>
        <span style="width: 12%;">营业额</span>
        <span style="width: 12%;">门店实际入账</span>
        <span style="width: 12%;">顾客归属</span>
        <span style="width: 12%;">操作账户</span>
      </div>
      <div class="ls-items">
        <div class="ls-item" v-if="list.length>0" v-for="(item,index) in list" :class="{'active':index%2!=0}">
          <div class="lsi-titel">
            <span class="lsi-od">单号:{{item.orderNo}}</span>
            <span class="lsi-od">时间:{{item.createTime}}</span>
            <span class="lsi-xq"><span class="odbtn" @click="toOrderInfo(item.id,item.orderType)">订单详情</span></span>
          </div>
          <div class="lsi-nr">
            <div class="lsi-dv" style="width: 12%">
              <div v-if="item.orderType==6" style="display: block;width: 100%">
                毛料
              </div>
              <div v-else style="display: block;width: 100%">
                <span v-if="item.memberId">
                  <span v-if="item.memberName" style="color: #2b94ff;cursor: pointer"
                        @click="toMbDetails(item.memberId)">{{item.memberName}}</span>
                  <span v-if="!item.memberName">用户已删除</span>
                </span>
                <span v-if="!item.memberId">散客</span>
                <span v-if="item.cardName" style="margin-left: 8px;">{{item.cardName==null?'':item.cardName}}</span>
              </div>
            </div>
            <div class="lsi-dv" style="width: 12%;">
              <div v-if="item.orderType==6" style="display: block;width: 100%">
                未知
              </div>
              <div v-else style="display: block;width: 100%">
                <span>{{item.orderSex==1?'男':'女'}}</span>
              </div>
            </div>
            <div class="lsi-hb" style="width: 28%;">
              <div class="lsih-sp" v-for="(it,index) in item.orderInfoList">
                <span class="lspan1" style="border-right: #C4C4C4 solid 1px;">
                 <div style="display: flex;flex-direction: row;align-items: center">
                  <span class="sp-type" v-if="it.commodityType==0">
                    <span v-if="it.commoditySign==0">(项目)</span>
                    <span v-if="it.commoditySign==1||it.commoditySign==4">(套餐消耗)</span>
                    <span v-if="it.commoditySign==2">(会员卡消耗)</span>
                    <span v-if="it.commoditySign==3">(年卡消耗)</span>
                    <span v-if="it.commoditySign==5||it.commoditySign==10">(商城消耗)</span>
                  </span>
                  <span class="sp-type" v-if="it.commodityType==1">(卖品)</span>
                  <span class="sp-type" v-if="it.commodityType==2">(套餐)</span>
                  <span class="sp-type" v-if="it.commodityType==3">(开卡)</span>
                  <span class="sp-type" v-if="it.commodityType==4">
                       <el-popover placement="right" width="200" trigger="click" @show="toCzItem(item.id)">
                      <div style="display:flex;flex-direction: column">
                        <span class="ob-sp" v-if="item.turnover">充值金额: <span class="ob-val">{{item.turnover}}</span></span>
                        <span class="ob-sp" >赠送金额: <span class="ob-val">{{it.giveAmount==null?'0':it.giveAmount}}</span></span>
                        <span class="ob-sp" v-if="czitem&&czitem.memberId">扣欠款: <span class="ob-val">{{czitem.amount}}</span></span>
                      </div>
                      <el-button type="text" slot="reference">(充值)</el-button>
                    </el-popover>
                  </span>
                  <span class="sp-type" v-if="it.commodityType==5">(品项)</span>
                  <span class="sp-type" v-if="it.commodityType==10">(成品)</span>
                  <span style="text-align: left;display: flex;flex-direction: column;justify-items: center;justify-content: center">
                    <span>{{it.commodityName}}</span>
                    <span style="height: 20px;line-height: 20px" v-if="it.giveAmount">赠送金：{{it.giveAmount}}</span>
                  </span>
                 </div>
                </span>
                <span class="lspan2">
                  <span style="display: flex;flex-direction: column;justify-content: center;align-items: center">
                    <span :class="{'lspan2-cost':it.giveAmount}">¥ {{it.originalPrice}}</span>
                  </span>
                </span>
              </div>
            </div>
            <div class="lsi-dv" style="width: 12%;">
                  <span class="lsiv-sp">¥ {{item.totalPrice}}
                  <span class="lsiv-sp" v-if="item.payType==0">(会员卡)</span>
                  <span class="lsiv-sp" v-if="item.payType==1">(赠送金)</span>
                  <span class="lsiv-sp" v-if="item.payType==2">(现金)</span>
                  <span class="lsiv-sp" v-if="item.payType==3">(微信)</span>
                  <span class="lsiv-sp" v-if="item.payType==4">(支付宝)</span>
                  <span class="lsiv-sp" v-if="item.payType==5">(美团)</span>
                  <span class="lsiv-sp" v-if="item.payType==6">(抖音)</span>
                  <span class="lsiv-sp" v-if="item.payType==7" style="cursor: pointer">
                    <el-popover placement="right" width="200" trigger="click" @show="toOrderBlend(item.id)">
                      <div style="display:flex;flex-direction: column">
                        <span class="ob-sp" v-if="orderBlend.card&&orderBlend.card>0">会员卡: <span class="ob-val">{{orderBlend.card}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.give&&orderBlend.give>0">赠送金: <span class="ob-val">{{orderBlend.give}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.cash&&orderBlend.cash>0">现  金: <span class="ob-val">{{orderBlend.cash}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.wx&&orderBlend.wx>0">微  信: <span class="ob-val">{{orderBlend.wx}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.ali&&orderBlend.ali>0">支付宝: <span class="ob-val">{{orderBlend.ali}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.mt&&orderBlend.mt>0">美  团: <span class="ob-val">{{orderBlend.mt}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.dy&&orderBlend.dy>0">抖  音: <span class="ob-val">{{orderBlend.dy}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.arrears&&orderBlend.arrears>0">欠  款: <span class="ob-val">{{orderBlend.arrears}}</span></span>
                        <span class="ob-sp" v-if="orderBlend.sj&&orderBlend.sj>0">商  家: <span class="ob-val">{{orderBlend.sj}}</span></span>
                      </div>
                      <el-button type="text" slot="reference">(混合支付)</el-button>
                    </el-popover>
                  </span>
                  <span class="lsiv-sp" v-if="item.payType==8">(欠款)</span>
                  <span class="lsiv-sp" v-if="item.payType==9">(商家自主)</span>
                  </span>
            </div>
            <div class="lsi-dv" style="width: 12%;"><span>{{item.turnover}}</span></div>
            <div class="lsi-dv" style="width: 12%;"><span>{{item.actual}}</span></div>
            <div class="lsi-dv" style="width: 12%;">
              <span>{{item.crossStore==1?item.kdName:item.deptName}}</span>
              <span style="width: 100px;text-align: left" v-if="item.crossStore==1">( 跨店 )</span>
            </div>
            <div class="lsi-dv" style="width: 12%;border-right: #C4C4C4 solid 0px;"><span>{{item.nickName}}</span></div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :pager-count="7"
          layout="total, prev, pager, next"
          :total="pagination.count"
          :page-count="pagination.pageCount"
          :current-page="pagination.pageNumber"
        ></el-pagination>
      </div>
    </div>
    <Xq v-if="xqVisible" :xqVisible="xqVisible" :id="orderId" @closeXQ="closeXQ"/>
  </div>
</template>

<script>

  import {getSwName, getToken} from "../../utils/auth";
  import Xq from './orderDetails'

  export default {
    components: {
      Xq
    },
    name: 'turnover',
    data() {
      return {
        swName: '',
        condition: '',
        orderNo: '',
        orderType: '',
        dates: [],
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        orderId: '',
        xqVisible: false,
        orderBlend: {},
        czitem:{},
        orderTypes: [
          {"name": "开单", "value": "0"},
          {"name": "套餐", "value": "1"},
          {"name": "办卡", "value": "2"},
          {"name": "充值", "value": "3"},
          {"name": "品项", "value": "4"},
        ],
      }
    },
    async created() {
      this.swName = getSwName()
      this.getSwOrderList()
    },
    methods: {
      toMbDetails(memberId) {
        this.$router.push(`/customers/memberInfo?memberId=` + memberId);
      },
      handleCurrentChange(page) {
        this.getSwOrderList(page)
      },

      toOrderInfo(orderId) {
        this.orderId = orderId
        this.xqVisible = true
      },
      closeXQ() {
        this.xqVisible = false
      },

      chaxun() {
        this.getSwOrderList()
      },

      //获取混合支付的详细明细
      async toOrderBlend(orderId) {
        if (this.orderBlend.orderId&&this.orderBlend.orderId==orderId){
          return ;
        }else {
          this.orderBlend={}
        }
        let res = await this.$get("/sw/getOrderBlend", {orderId: orderId})
        if (res.orderBlend) {
          this.orderBlend = res.orderBlend
        }
      },

      //查看充值是否存在还款
      async toCzItem(orderId) {
        if (this.czitem.hkOrderId&&this.czitem.hkOrderId==orderId){
          return ;
        }else {
          this.czitem={}
        }
        let res = await this.$get("/sw/getCzItem", {orderId: orderId})
        if (res.czitem) {
          this.czitem = res.czitem
        }
      },
      //门店流水
      async getSwOrderList(page) {
        let currentPage = page || 1;
        let staDate = "";
        let endDate = "";
        if (this.dates&&this.dates.length > 0) {
          staDate = this.dates[0];
          endDate = this.dates[1];
        }
        let orderType = "";
        if (this.orderType == "-1" || this.orderType == "") {
          orderType = ""
        } else {
          orderType = this.orderType
        }
        let data = {
          token: getToken(),
          condition: this.condition,
          orderNo: this.orderNo,
          orderType: orderType,
          staDate: staDate,
          endDate: endDate,
          orderStatus: 1,
          ht: 5,
          currentPage
        }
        this.loading = true
        let res = await this.$get("/sw/getSwOrderList", data)
        this.loading = false
        this.list = res.dataSet.list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },
    },
  }

</script>


<style scoped>
  .ls {
    padding: 0 15px;
    background: #fff;
    border-bottom: #e4e4e4 solid 1px;
  }

  .ls .eti {
    display: block;
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
  }

  .ls-nr {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    margin-top: 20px;
  }

  .ls-titel {
    display: flex;
    flex-direction: row;
    background-color: #EEEEEE;
    margin-bottom: 16px;
  }

  .ls-titel span {
    text-align: center;
    line-height: 32px;
  }

  .ls-items {
    width: 100%;
  }

  .ls-item {
    margin-bottom: 30px;
  }

  .lsi-titel {
    display: flex;
    flex-direction: row;
    line-height: 30px;
    padding: 0px 25px;
    align-items: center;
    border: #C4C4C4 solid 1px;
  }

  .lsi-od {
    width: 180px;
  }

  .lsi-xq {
    width: 80%;
    text-align: right;
    height: 20px;
  }

  .lsi-dv {
    display: flex;
    justify-items: center;
    align-items: center;
    border-right: #C4C4C4 solid 1px;
    /*border-bottom: #C4C4C4 solid 1px;*/
    text-align: center;
  }

  .lsi-dv span {
    width: 100%;
  }

  .lsi-nr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    font-size: 10px;
    border-left: #C4C4C4 solid 1px;
    border-right: #C4C4C4 solid 1px;
    border-bottom: #C4C4C4 solid 1px;
  }

  .lsi-nr .lsi-hb {
    width: 26%;
    border-right: #C4C4C4 solid 1px;
  }

  .lsih-sp {
    display: block;
    line-height: 32px;
  }

  .lsih-sp .lspan1 {
    display: inline-block;
    width: 60%;
  }

  .lsih-sp .lspan2 {
    display: inline-block;
    width: 39%;
  }

  .lspan2-cost {
    position: relative;
    top: 10px;
  }

  .sp-type {
    display: inline-block;
    float: left;
    padding-left: 10px;
    margin-right: 18px;
    color: #ff4d51;
  }

  .ls-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 12px 8px;
  }

  .pagination {
    margin: 12px 0 25px 0;
    text-align: right;
  }

  .active {
    background-color: #F6F6F6;
  }

  .ob-sp {
    height: 23px;
    line-height: 23px;
    text-align: right;
  }

  .ob-val {
    display: inline-block;
    width: 120px;
    text-align: left;
    padding-left: 3px;
  }

  .odbtn {
    float: right;
    color: #FFFFFF;
    cursor: pointer;
    background-color: #6E87CC;
    height: 20px;
    line-height: 20px;
    padding: 0 12px
  }
</style>
